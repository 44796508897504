@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root,
  html,
  body {
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  #layers {
    width: 100%;
    height: 200%;
    position: fixed;
    top: -100%;
    overflow: hidden;
    pointer-events: none;
  }

  .layer {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    -webkit-animation-name: zoom;
    animation-name: zoom;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }

  .star {
    width: 11px;
    height: 11px;
    display: inline-block;
    position: absolute;
    fill: #ffffff;
  }

  .star:nth-child(3n + 3) {
    fill: #fec777;
  }

  .star:nth-child(5n + 5) {
    fill: #498fb3;
  }

  @-webkit-keyframes zoom {
    0% {
      -webkit-transform: scale(0.1);
      transform: scale(0.1);
      opacity: 0;
    }
    90% {
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
      opacity: 0;
    }
  }

  @keyframes zoom {
    0% {
      -webkit-transform: scale(0.1);
      transform: scale(0.1);
      opacity: 0;
    }
    90% {
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
      opacity: 0;
    }
  }

  html {
    /* 10 px */
    font-size: 62.5%;
  }

  body {
    @apply text-body;
  }
}

.marching-rect {
  fill: none;
  stroke: #00ffba;
  vector-effect: non-scaling-stroke;
  stroke-dasharray: 6px;
  stroke-dashoffset: 12px;
  animation: stroke 0.5s linear infinite;
  stroke-width: 4px;
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}
