*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Roboto, Segue UI, Arial, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

:root, html, body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#layers {
  pointer-events: none;
  width: 100%;
  height: 200%;
  position: fixed;
  top: -100%;
  overflow: hidden;
}

.layer {
  opacity: 0;
  width: 100%;
  height: 100%;
  animation-name: zoom;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
}

.star {
  fill: #fff;
  width: 11px;
  height: 11px;
  display: inline-block;
  position: absolute;
}

.star:nth-child(3n+3) {
  fill: #fec777;
}

.star:nth-child(5n+5) {
  fill: #498fb3;
}

@keyframes zoom {
  0% {
    opacity: 0;
    transform: scale(.1);
  }

  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-weight: normal;
  line-height: 2.4rem;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-\[-\.5vh\] {
  bottom: -.5vh;
}

.bottom-\[1\%\] {
  bottom: 1%;
}

.bottom-\[15\%\] {
  bottom: 15%;
}

.bottom-\[22\%\] {
  bottom: 22%;
}

.bottom-\[5\%\] {
  bottom: 5%;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-\[20\%\] {
  left: 20%;
}

.left-\[80\%\] {
  left: 80%;
}

.right-0 {
  right: 0;
}

.right-\[20\%\] {
  right: 20%;
}

.right-xxxl {
  right: 6.4rem;
}

.top-0 {
  top: 0;
}

.top-\[17\%\] {
  top: 17%;
}

.top-\[40\%\] {
  top: 40%;
}

.top-\[46\%\] {
  top: 46%;
}

.top-\[5\%\] {
  top: 5%;
}

.top-\[70\%\] {
  top: 70%;
}

.top-\[80\%\] {
  top: 80%;
}

.top-xxxl {
  top: 6.4rem;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.m-4 {
  margin: 1rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-auto {
  margin-top: auto;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.aspect-\[10\/1\] {
  aspect-ratio: 10;
}

.aspect-\[4\/3\] {
  aspect-ratio: 4 / 3;
}

.aspect-\[64\/847\] {
  aspect-ratio: 64 / 847;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-24 {
  height: 6rem;
}

.h-9 {
  height: 2.25rem;
}

.h-\[12vh\] {
  height: 12vh;
}

.h-\[15\.5vh\] {
  height: 15.5vh;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[32px\] {
  height: 32px;
}

.h-\[36px\] {
  height: 36px;
}

.h-\[37\.5\%\] {
  height: 37.5%;
}

.h-\[42px\] {
  height: 42px;
}

.h-\[44px\] {
  height: 44px;
}

.h-\[48px\] {
  height: 48px;
}

.h-\[55\%\] {
  height: 55%;
}

.h-\[57px\] {
  height: 57px;
}

.h-\[90\%\] {
  height: 90%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-20 {
  max-height: 5rem;
}

.max-h-\[40vh\] {
  max-height: 40vh;
}

.max-h-\[55px\] {
  max-height: 55px;
}

.max-h-\[60vh\] {
  max-height: 60vh;
}

.min-h-\[32px\] {
  min-height: 32px;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-10 {
  width: 2.5rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-3\/12 {
  width: 25%;
}

.w-4\/12 {
  width: 33.3333%;
}

.w-64 {
  width: 16rem;
}

.w-\[12vh\] {
  width: 12vh;
}

.w-\[17\%\] {
  width: 17%;
}

.w-\[24px\] {
  width: 24px;
}

.w-\[300px\] {
  width: 300px;
}

.w-\[32px\] {
  width: 32px;
}

.w-\[38\%\] {
  width: 38%;
}

.w-\[42px\] {
  width: 42px;
}

.w-\[44px\] {
  width: 44px;
}

.w-\[45\%\] {
  width: 45%;
}

.w-\[80px\] {
  width: 80px;
}

.w-\[calc\(100\%-8rem\)\] {
  width: calc(100% - 8rem);
}

.w-auto {
  width: auto;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.min-w-full {
  min-width: 100%;
}

.max-w-\[100vw\] {
  max-width: 100vw;
}

.max-w-none {
  max-width: none;
}

.flex-grow {
  flex-grow: 1;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1\/2 {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.auto-rows-max {
  grid-auto-rows: max-content;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-\[1fr_1fr_2fr\] {
  grid-template-columns: 1fr 1fr 2fr;
}

.grid-cols-\[1fr_2fr\] {
  grid-template-columns: 1fr 2fr;
}

.grid-cols-\[2fr_1fr\] {
  grid-template-columns: 2fr 1fr;
}

.grid-cols-\[2fr_1fr_1fr\] {
  grid-template-columns: 2fr 1fr 1fr;
}

.grid-cols-\[auto_30px\] {
  grid-template-columns: auto 30px;
}

.grid-cols-\[minmax\(auto\,_200px\)_minmax\(auto\,_175px\)\] {
  grid-template-columns: minmax(auto, 200px) minmax(auto, 175px);
}

.grid-cols-result {
  grid-template-columns: 1fr 2fr minmax(auto, 90px) 2fr 1fr;
}

.grid-cols-resultDetail {
  grid-template-columns: 6fr minmax(auto, 200px) minmax(auto, 175px);
}

.grid-rows-\[minmax\(57px\,_auto\)\] {
  grid-template-rows: minmax(57px, auto);
}

.grid-rows-\[repeat\(5\,_max-content\)_auto\] {
  grid-template-rows: repeat(5, max-content) auto;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-stretch {
  justify-content: stretch;
}

.justify-items-center {
  justify-items: center;
}

.gap-1 {
  gap: .25rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-xxxxl {
  gap: 8rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-\[4px\] {
  column-gap: 4px;
}

.gap-x-\[8px\] {
  column-gap: 8px;
}

.gap-y-3 {
  row-gap: .75rem;
}

.self-center {
  align-self: center;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-\[99px\] {
  border-radius: 99px;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-s {
  border-start-start-radius: .25rem;
  border-end-start-radius: .25rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border-2 {
  border-width: 2px;
}

.border-large {
  border-width: 4px;
}

.border-t, .border-t-small {
  border-top-width: 1px;
}

.border-neutral-400 {
  --tw-border-opacity: 1;
  border-color: rgb(77 100 113 / var(--tw-border-opacity));
}

.border-neutral-50 {
  --tw-border-opacity: 1;
  border-color: rgb(242 247 250 / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(0 255 186 / var(--tw-border-opacity));
}

.bg-neutral-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(201 212 218 / var(--tw-bg-opacity));
}

.bg-neutral-200\/40 {
  background-color: #c9d4da66;
}

.bg-neutral-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(77 100 113 / var(--tw-bg-opacity));
}

.bg-neutral-400\/20 {
  background-color: #4d647133;
}

.bg-neutral-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(58 78 90 / var(--tw-bg-opacity));
}

.bg-neutral-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(10 22 29 / var(--tw-bg-opacity));
}

.bg-noduleSeverity-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(201 212 218 / var(--tw-bg-opacity));
}

.bg-noduleSeverity-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 217 144 / var(--tw-bg-opacity));
}

.bg-noduleSeverity-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 160 72 / var(--tw-bg-opacity));
}

.bg-noduleSeverity-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 94 29 / var(--tw-bg-opacity));
}

.bg-noduleSeverity-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(230 44 76 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(0 255 186 / var(--tw-bg-opacity));
}

.bg-primary\/10 {
  background-color: #00ffba1a;
}

.bg-opacity-70 {
  --tw-bg-opacity: .7;
}

.bg-cover {
  background-size: cover;
}

.bg-top {
  background-position: top;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-xs {
  padding: .8rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[10vh\] {
  padding-left: 10vh;
  padding-right: 10vh;
}

.px-\[12vh\] {
  padding-left: 12vh;
  padding-right: 12vh;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[12px\] {
  padding-top: 12px;
  padding-bottom: 12px;
}

.py-\[2vh\] {
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.py-\[5vh\] {
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-\[0px\] {
  padding-bottom: 0;
}

.pb-\[8vh\] {
  padding-bottom: 8vh;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pl-xxl {
  padding-left: 4.8rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pt-\[9vh\] {
  padding-top: 9vh;
}

.pt-xxxl {
  padding-top: 6.4rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.text-caption {
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.8rem;
}

.text-display1 {
  font-size: 3.2rem;
  font-weight: bold;
  line-height: 4.8rem;
}

.text-display2 {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 3.6rem;
}

.text-display3 {
  font-size: 2rem;
  font-weight: bold;
  line-height: 3rem;
}

.text-display4 {
  font-size: 2rem;
  font-weight: normal;
  line-height: 3rem;
}

.text-display6 {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 2.1rem;
}

.text-title {
  font-size: 5rem;
  font-weight: bold;
  line-height: 5.4rem;
}

.font-bold {
  font-weight: 700;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-neutral-100 {
  --tw-text-opacity: 1;
  color: rgb(224 233 238 / var(--tw-text-opacity));
}

.text-neutral-300 {
  --tw-text-opacity: 1;
  color: rgb(156 176 188 / var(--tw-text-opacity));
}

.text-neutral-400 {
  --tw-text-opacity: 1;
  color: rgb(77 100 113 / var(--tw-text-opacity));
}

.text-neutral-50 {
  --tw-text-opacity: 1;
  color: rgb(242 247 250 / var(--tw-text-opacity));
}

.text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgb(10 22 29 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(0 255 186 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.outline {
  outline-style: solid;
}

.outline-double {
  outline-style: double;
}

.outline-1 {
  outline-width: 1px;
}

.outline-primary {
  outline-color: #00ffba;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.marching-rect {
  fill: none;
  stroke: #00ffba;
  vector-effect: non-scaling-stroke;
  stroke-dasharray: 6;
  stroke-dashoffset: 12px;
  stroke-width: 4px;
  animation: .5s linear infinite stroke;
}

@keyframes stroke {
  to {
    stroke-dashoffset: 0;
  }
}

.placeholder\:font-light::placeholder {
  font-weight: 300;
}

.placeholder\:text-neutral-100::placeholder {
  --tw-text-opacity: 1;
  color: rgb(224 233 238 / var(--tw-text-opacity));
}

.odd\:bg-neutral-400\/20:nth-child(odd) {
  background-color: #4d647133;
}

.even\:bg-primary\/10:nth-child(2n) {
  background-color: #00ffba1a;
}

.hover\:bg-neutral-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(156 176 188 / var(--tw-bg-opacity));
}

.hover\:bg-neutral-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(77 100 113 / var(--tw-bg-opacity));
}

.hover\:text-neutral-900:hover {
  --tw-text-opacity: 1;
  color: rgb(10 22 29 / var(--tw-text-opacity));
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(0 255 186 / var(--tw-text-opacity));
}

.hover\:brightness-110:hover {
  --tw-brightness: brightness(1.1);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (min-width: 640px) {
  .sm\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .lg\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:max-h-\[40vh\] {
    max-height: 40vh;
  }
}

/*# sourceMappingURL=index.3d3dc2a6.css.map */
